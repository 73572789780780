<template>
  <v-container fluid class="pa-0">
    <banner :title="$t('term.title')"></banner>
    <v-row class="px-lg-16 ma-0">
      <v-container fluid class="pa-lg-16 ma-0 pa-10">
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.termsOfUse')}}</h2>
            <p class="p josefin-light black-grin">
              {{$t('term.termsOfUseDescription')}}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3 ">{{$t('term.electronicCommunications')}}</h2>
            <p class="p josefin-light black-grin"
            v-html="$t('term.electronicCommunicationsDescription')">
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.copyright')}}</h2>
            <p class="p josefin-light black-grin text-justify">
              {{$t('term.copyrightDescription')}}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.licensesAndAccessToTheWebsite')}}</h2>
            <p class="p josefin-light black-grin text-justify">
              {{$t('term.licensesAndAccessToTheWebsiteDescription')}}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.rightsOfUse')}}</h2>
            <p class="p josefin-light black-grin text-justify">
              {{$t('term.rightsOfUseDescription')}}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.productInformation')}}</h2>
            <p class="p josefin-light black-grin text-justify">
             {{$t('term.productInformationDescription')}}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.photographs')}}</h2>
            <p class="p josefin-light black-grin text-justify">
              {{$t('term.photographsDescription')}}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.inventory')}}</h2>
            <p class="p josefin-light black-grin text-justify">
             {{$t('term.inventoryDescription')}}
            </p>
            <ul class="p josefin-light black-grin text-justify">
              <li>
                {{$t('term.inventoryRuleOne')}}
              </li>
              <li>
                {{$t('term.inventoryRuleTwo')}}
              </li>
              <li>
                {{$t('term.inventoryRuleThree')}}
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.priceAndPromotions')}}</h2>
            <p class="p josefin-light black-grin text-justify"
            v-html="$t('term.priceAndPromotionsDescription')">
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.knowledgeOfTheOrders')}}</h2>
            <p class="p josefin-light black-grin text-justify">
              {{$t('term.knowledgeOfTheOrdersDescription1')}}
            </p>
            <ul class="p josefin-light black-grin text-justify mb-3">
              <li>
                {{$t('term.check1')}}
              </li>
              <li>
               {{$t('term.check2')}}
              </li>
              <li>
                {{$t('term.check3')}}
              </li>
              <li>
                {{$t('term.check4')}}
              </li>
            </ul>
            <p class="p josefin-light black-grin text-justify"
            v-html="$t('term.knowledgeOfTheOrdersDescription2')">
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.paymentMethod')}}</h2>
            <p class="p josefin-light black-grin text-justify"
            v-html="$t('term.paymentMethodDescription')">
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.limitsOfLiability')}}</h2>
            <p class="p josefin-light black-grin text-justify"
            v-html="$t('term.limitsOfLiabilityDescription')">
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{$t('term.websitePolicies')}}</h2>
            <p class="p josefin-light black-grin text-justify">
             {{$t('term.websitePoliciesDescription')}}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import banner from "@/components/home/banner.vue";

export default {
  components: {
    banner
  }
};
</script>
